<template>
  <div>
    <h1>Recargo</h1>
<v-list v-if="items.length > 0">
    <v-list-item v-for="item in items" :key="item.id">
      <v-list-item-content>
       
       
        <!-- Añade esta parte para mostrar cuotas y porcentaje de recargo -->
        <v-list-item-subtitle v-if="item.cuotas">
          Cuotas: {{ item.cuotas }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.porcentaje">
          Porcentaje: {{ item.porcentaje }}%
        </v-list-item-subtitle>
        <!-- Fin de la parte añadida -->
      </v-list-item-content>
    </v-list-item>
  </v-list>
  </div>
</template>

<script>
export default {
  name: "RecargoSoporte",
  data() {
    return {
      items: [],
    };
  },
  
  created() {
    this.axios
      .get("/soporte/recargo")
      .then((response) => {
        this.items = response.data;
      })
      .catch((e) => {
        console.log(e);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e.response.data}`,
          icon: "mdi-information",
          timeout: 3000,
        });
      });
  },
  
};
</script>

<style lang="scss" scoped>
</style>